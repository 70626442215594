const identificationMixin = {
  methods: {
    isLetterOrNumber (e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[A-Za-z0-9]+$/.test(char)) return true
      else e.preventDefault()
    }
  }
}
export default identificationMixin
